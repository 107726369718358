import { defineAsyncComponent } from 'vue'
import { $vfm } from 'vue-final-modal'

export function useTableHandlers() {
  const openModal = (info, title, footer, footerProps, onCLose) => {
    $vfm.show({
      component: defineAsyncComponent(() => import('@/application/components/modals/TableDetailsModal.vue')),
      bind: {
        info,
        title,
        footer,
        footerProps,
      },
      on: {
        close: () => {
          if (onCLose) {
            onCLose()
          }
          $vfm.hideAll()
        },
      },
    })
  }
  return {
    openModal,
  }
}
