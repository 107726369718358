<template>
  <div class="table-responsive" v-if="list.length > 0">
    <table class="table table-striped table-hover" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :class="item === 'text' ? 'col-md-4' : ''"  :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list" :key="row.id" @click="prepareInfo(row)">
          <td :data-label="$t('recipient')">{{ row.recipient }}</td>
          <td class="sentMessageTable-textWrapper  d-none d-md-block" :data-label="$t('text')">
            <span class="sentMessageTable-text">{{ row.text }}</span>
          </td>
          <td class="d-block d-md-none" :data-label="$t('text')">
            {{ row.text }}
          </td>
          <td :data-label="$t('type')">{{ $t(row.type) }}</td>
          <td :data-label="$t('date')" class="sentMessageTable-date">{{ humanDateTime(row.create_date, $i18n.locale) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useHumanDate } from '@/application/composables/humanDate.js'
import { useTableHandlers } from '@/application/composables/messagesTable.js'

export default {
  name: 'InboundMessagesTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { humanDateTime } = useHumanDate()
    const i18n = useI18n()
    const { openModal } = useTableHandlers()
    const fields = ['recipient', 'text', 'type', 'date']
    const prepareInfo = (info) => {
      const obj = {
        recipient: info.recipient,
        text: info.text,
        type: info.type === 'text' ? i18n.t('text') : i18n.t('reaction'),
        sandbox: info.sandbox ? i18n.t('yes') : i18n.t('no'),
        reaction: info.reaction,
        date: humanDateTime(info.create_date, i18n.locale),
      }
      if (info.reaction) obj.reaction = info.reaction
      openModal(obj)
    }
    return {
      humanDateTime,
      prepareInfo,
      fields,
    }
  },
}
</script>
